<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input
            :key="`input-${key}`"
            :fields="fields"
            v-model="data"
          ></n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                :hide-create="true"
                v-if="$can('update', resource)"
              ></n-button-save-option>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";

const NewsCategoryRepository = Repository.get("newsCategory");

export default {
  components: {
    BRow,
    BCol,
    BCard,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      key: 1,
      data: {
        imageId: null,
        image: null,
        nameEn: null,
        nameKm: null,
        isEnable: true,
      },
      loading: false,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      NewsCategoryRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = {
            ...data,
          };
          this.key++;
        }
      });
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      NewsCategoryRepository.update(this.$route.params.id, this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.editForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;

    const resource = "news-category";
    const route = "news-category";

    return { fields, resource, route };
  },
};
</script>